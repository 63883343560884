import produce from 'immer';
import { SET_LOCALE } from './constants';
import { getItem } from '../../utils/localStorage';
import { DEFAULT_LOCALE } from '../../i18n';

const SELECTED_LOCALE = getItem('locale');

export const initialState = {
  locale: SELECTED_LOCALE ? SELECTED_LOCALE : DEFAULT_LOCALE,
};

/* eslint-disable default-case */
const languageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_LOCALE:
        draft.locale = action.payload;
        break;
    }
  });

export default languageReducer;
