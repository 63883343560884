import axios from 'axios';
import config from 'config';
import { sessionExpired, setToken } from 'containers/App/actions';
import { makeSelectToken } from 'containers/App/selectors';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import jwtDecode from 'jwt-decode';
import messages from 'messages';
import { call, put, select } from 'redux-saga/effects';
import { removeItem, setItem } from 'utils/localStorage';
import { ENDPOINTS } from '../constants';
import { LOGIN } from '../routes';
import { logout } from 'containers/App/actions';
import { store } from 'index';
import { setSnackbar } from '../store/snackbar/actions';

export const api = axios.create({
  baseURL: config.api.baseUrl,
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    const payload = error?.response;
    switch (payload?.status) {
      case 400:
      case 403:
      case 404:
      case 500:
        let errorData = error?.response?.data;
        if (errorData != null && errorData instanceof ArrayBuffer) {
          errorData.message = JSON.parse(
            new TextDecoder().decode(errorData)
          ).message;
        }
        store.dispatch(
          setSnackbar({
            open: true,
            severity: 'error',
            message: errorData?.message
              ? errorData?.message
              : 'Something went wrong!',
          })
        );
        break;
      case 401:
        store.dispatch(logout());
        window.location = LOGIN;
        break;
      default:
        return Promise.reject(error.response);
    }
  }
);

export const getApi = () => {
  return api;
};

export default function* request({
  url,
  method,
  data,
  headers = {},
  responseType = '',
}) {
  try {
    let token = yield select(makeSelectToken());
    if (token) {
      if (Date.now() / 1000 >= jwtDecode(token).exp) {
        token = yield call(refreshToken, token);
      }
      headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    if (error.status === 500) {
      yield call(removeItem, 'token');
      yield put(sessionExpired());
      yield put(
        enqueueSnackbar({
          message: messages.sessionExpired,
        })
      );
    }
    throw error;
  }
  return yield call(api, { method, url, headers, data, responseType });
}

export function* refreshToken(prevToken) {
  const { accessToken: token } = yield call(api, {
    url: ENDPOINTS.AUTH_REFRESH_TOKEN,
    method: 'post',
    headers: {
      Authorization: `Bearer ${prevToken}`,
    },
  });

  yield call(setItem, 'token', token);

  yield put(setToken(token));

  return token;
}
