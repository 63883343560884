import produce from 'immer';
import { getItem } from 'utils/localStorage';
import {
  FETCH_AUTHENTICATED_USER_SUCCESS,
  FETCH_COMMON_DATA_SUCCESS,
  LOGOUT_SUCCESS,
  SESSION_EXPIRED,
  SET_TOKEN,
} from './constants';

export const initialState = {
  token: getItem('token') || null,
  user: null,
  interests: [],
  disabilities: [],
};

/* eslint-disable default-case */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_AUTHENTICATED_USER_SUCCESS:
        draft.user = action.user;
        break;
      case LOGOUT_SUCCESS:
      case SESSION_EXPIRED:
        draft.user = null;
        draft.token = null;
        break;
      case SET_TOKEN:
        draft.token = action.token;
        break;
      case FETCH_COMMON_DATA_SUCCESS:
        draft.interests = action.commonData.interests;
        draft.disabilities = action.commonData.disabilities;
        break;
    }
  });

export default appReducer;
