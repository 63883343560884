import React from 'react';
import styles from './LoadingSpinner.module.scss';
import spinner from '../../assets/images/spinner.gif';

const LoadingSpinner = () => (
  <div className={styles.loadingSpinner}>
    <img src={spinner} alt="loading spinner" style={{ width: '4%' }} />
  </div>
);

export default LoadingSpinner;
